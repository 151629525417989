/**
 * This function get the available languages from Cloud Translate API, in the language of the code you send
 * @param {String} langCode
 * @returns {Promise<ReadonlyArray<Object>>}
 */
export default async function getSupportedLanguages(langCode) {
  const options = {
    method: 'POST',
    body: JSON.stringify({
      target: langCode,
    }),
  };
  const url = `https://translation.googleapis.com/language/translate/v2/languages?key=${import.meta.env.VITE_CLOUD_TRANSLATE_KEY}`;
  const request = await fetch(url, options);
  const response = await request.json();
  return response.data.languages;
}

/**
 * This function get the translation of the content you sent from Cloud Translate API, in the target language you provided.
 * @param {String} targetLang
 * @param {String} content
 * @returns {Promise<Object>}
 */
export async function translateContent(targetLang, content) {
  const options = {
    method: 'POST',
    body: JSON.stringify({
      q: content,
      target: targetLang,
    }),
  };
  const url = `https://translation.googleapis.com/language/translate/v2?key=${import.meta.env.VITE_CLOUD_TRANSLATE_KEY}`;
  const request = await fetch(url, options);
  const response = await request.json();
  return response.data.translations[0];
}
