export function getURLparam(param) {
  const uri = window.location.search.substring(1);
  const params = new URLSearchParams(uri);
  return params.get(param);
}

export function getSlug(str) {
  let result;

  if (!str || str === '') return '';

  result = str.toLowerCase().trim();

  // remove accents, swap ñ for n, etc
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/,:;';
  const to = 'aaaaeeeeiiiioooouuuunc-----';
  for (let i = 0; i < from.length; i += 1) {
    result = result.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  result = result.replace(/[^a-z0-9 _]/g, '-') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return result;
}

export function isURL(str) {
  try {
    const url = new URL(str);
    return url.toString();
  } catch (_) {
    return false;
  }
}

export function isImage(str) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)/.test(str);
}
