import { initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyBfONQeE8VLk1zWzJ5PDzAZEA1a7gl9Y2k',
  authDomain: 'captag-events.firebaseapp.com',
  databaseURL: 'https://captag-events-default-rtdb.europe-west1.firebasedatabase.app/',
  projectId: 'captag-events',
  storageBucket: 'captag-events.appspot.com',
  messagingSenderId: '418843808210',
  appId: '1:418843808210:web:5e772237a25f457776f80b',
  measurementId: 'G-NH1GHSQ6S9',
};

let firebaseService;

/**
 * Return instance if exists else connect to database
 * @param tokenID
 * @return {Promise<null|Database>}
 */
export default async function getInstance(tokenID) {
  if (firebaseService === undefined) {
    const app = initializeApp(firebaseConfig);

    const auth = getAuth(app);
    try {
      await signInWithCustomToken(auth, tokenID);
    } catch (e) {
      return null;
    }
    firebaseService = await getDatabase(app);
  }
  return firebaseService;
}
