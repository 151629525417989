/**
     * Get all the configuration from token and check errors
     * @returns {Promise<void>}
     */
import getInstance from '@/services/firebaseService';
import { getURLparam } from '@/utils';
import { jwtDecode } from 'jwt-decode';

async function setConfigAction() {
  const token = getURLparam('tk');
  if (!token) {
    this.onError = true;
    this.errorType = 'noAccessError';
    return;
  }
  const { claims } = jwtDecode(token);
  if (!await getInstance(token)) {
    this.onError = true;
    this.errorType = 'dbConnectionError';
    return;
  }
  if (!claims.eventID) {
    this.onError = true;
    this.errorType = 'noEventIDError';
    return;
  }
  this.eventID = claims.eventID;
  if (!claims.locales) {
    this.onError = true;
    this.errorType = 'noLocalesError';
    return;
  }
  this.eventLocales = claims.locales;
  this.htmlAttr = claims.htmlAttr;
  this.textAttr = claims.textAttr;
  this.currentAttr = claims.globalAttr || [...claims.htmlAttr, ...claims.textAttr].sort();
  this.contextID = claims.context;
  this.elementID = claims.elementID;
  this.userLang = claims.lang ? claims.lang : 'fr';
  this.loaded = true;
}

function setSaveInProgressAction(newValue) {
  this.saveInProgress = newValue;
}

export default {
  setConfigAction,
  setSaveInProgressAction,
};
