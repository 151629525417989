<template>
  <div v-if="props.htmlType">
    <Editor
        :init="tinyConfig"
        v-model="value"
        plugins="link image emoticons wordcount lists"
        toolbar="undo redo | forecolor backcolor removeformat | fontsize blocks | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | numlist bullist | pastetext | outdent indent | link image emoticons | customCode"
    />
  </div>
  <div class="input-group mb-3" v-else>
    <input class="form-control" v-model="value" :placeholder="inputPlaceholder"/>
    <VTooltip v-if="isURL(value) && isImage(value)" placement="left" class="input-group-text">
      <a target="_blank" :href="value" class="link-dark">
        <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
      </a>
      <template #popper>
        <ImagePreview :image="value" />
      </template>
    </VTooltip>
    <span v-else-if="isURL(value)" class="input-group-text" :id="`dataKey-input-group`">
      <a target="_blank" :href="value" class="link-dark">
        <i class="fa-solid fa-arrow-up-right-from-square"></i>
      </a>
    </span>
  </div>
</template>

<script setup>
import htmlSourceModal from '@/components/Modals/htmlSourceModal.vue';
import Editor from '@tinymce/tinymce-vue';
import 'tinymce/tinymce';

import 'tinymce/icons/default/icons.min';
import 'tinymce/models/dom/model.min';

import 'tinymce/themes/silver/theme.min';

import 'tinymce/skins/ui/oxide/skin';

import '../locales/tinymce/fr_FR';

import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/lists';

import { useModal } from 'vue-final-modal';
import {
  computed,
  defineProps,
  defineEmits,
} from 'vue';
import { isURL, isImage } from '@/utils';
import useGlobalStore from '../stores/globalStore';
import useTranslationStore from '../stores/translationsStore';
import ImagePreview from './ImagePreview.vue';

const globalStore = useGlobalStore();
const translationsStore = useTranslationStore();
const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  dataKey: {
    type: String,
    required: true,
  },
  htmlType: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const emit = defineEmits(['update:modelValue']);

const value = computed({
  get() {
    return props.modelValue || '';
  },
  set(newValue) {
    emit('update:modelValue', newValue || '');
  },
});

const { open, close } = useModal({
  component: htmlSourceModal,
  attrs: {
    value,
    onUpdate(newValue) {
      value.value = newValue;
    },
    onConfirm() {
      close();
    },
  },
});

const tinyConfig = {
  license_key: 'gpl',
  height: 300,
  branding: false,
  menubar: false,
  toolbar_mode: 'wrap',
  language: globalStore.userLang === 'fr' ? 'fr_FR' : '',
  font_size_formats: '8px 10px 12px 14px 18px 20px 24px 28px 32px 36px 40px 48px 52px 56px 60px 64px 68px 70px 72px 74px 78px 80px 84px 88px 90px 92px 96px 98px',
  paste_as_text: true,
  skin_url: 'default',
  content_css: 'https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css',
  content_css_cors: true,
  content_style: '@import url(\'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap\');body { font-family: \'Roboto\', sans-serif !important;}',
  setup: (editor) => {
    editor.ui.registry.addButton('customCode', {
      icon: 'sourcecode',
      onAction: () => {
        open();
      },
    });
  },
};

const inputPlaceholder = computed(() => {
  if (globalStore.contextID !== 'webapp') return '';
  if (translationsStore.webappDictionary[translationsStore.currentEditLang] && translationsStore.webappDictionary[translationsStore.currentEditLang][props.dataKey]) {
    return translationsStore.webappDictionary[translationsStore.currentEditLang][props.dataKey];
  }
  if (translationsStore.webappDictionary.fr[props.dataKey]) return translationsStore.webappDictionary.fr[props.dataKey];
  return '';
});
</script>

<style scoped>
</style>
