<template>
  <div v-if="!translationStore.contentLoaded || !translationStore.translationLoaded" class="vh-100 d-flex justify-content-center align-items-center">
    <h2>{{ t('loadingMessage') }}</h2>
  </div>
  <div v-else class="px-3">
    <TopBar />
    <ContentEditor />
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import TopBar from '../components/TopBar.vue';
import ContentEditor from '../components/ContentEditor.vue';
import useGlobalStore from '../stores/globalStore';
import useTranslationStore from '../stores/translationsStore';

const globalStore = useGlobalStore();
const translationStore = useTranslationStore();
const { t } = useI18n();

translationStore.getElementsAction(globalStore.eventID, globalStore.contextID, globalStore.elementID).then(() => {
  translationStore.setCurrentElementAction(globalStore.elementID, globalStore.contextID);
  translationStore.getAvailableAutoLanguagesAction();
});
</script>

<style scoped>

</style>
