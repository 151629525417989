<template>
  <div class="min-vh-100 d-flex justify-content-center align-items-center align-middle">
    <div class="row w-100 text-center">
      <div class="col-12">
        <h1 class="title">{{ t('projectTitle') }}</h1>
      </div>
      <div class="col-12 mb-1">
        <h5 class="subtitle">{{ t('error') }}</h5>
      </div>
      <div class="col-12 mt-3">
        <h3 class="text-secondary">{{ t(`errors.${globalStore.errorType}`) }}</h3>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import useGlobalStore from '../stores/globalStore';

const globalStore = useGlobalStore();
const { t } = useI18n();
</script>

<style scoped>
.title {
  font-size: 70px;
}

.subtitle {
  font-size: 30px;
}

@media (max-width: 768px) {
  .title {
    font-size: 32px;
  }

  .subtitle {
    font-size: 18px;
  }
}
</style>
