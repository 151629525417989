import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createVfm } from 'vue-final-modal';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import {
  VTooltip,
  VClosePopper,
  Dropdown,
  Tooltip,
  Menu,
} from 'floating-vue';
import 'floating-vue/dist/style.css';
import * as Sentry from '@sentry/vue';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'tinymce/skins/content/default/content.css';
import 'vue-final-modal/style.css';
import '../public/style/custom.scss';
import './index.scss';
import Notifications from '@kyvg/vue3-notification';
import App from './App.vue';
import { loadLocaleMessages, setupI18n } from './i18n';

const app = createApp(App);

const i18n = setupI18n();

const vfm = createVfm();

Sentry.init({
  app,
  environment: import.meta.env.DEV ? 'Development' : 'Production',
  dsn: 'https://921a5763a164d103b7a6908dfb25d219@o328440.ingest.sentry.io/4506534940573696',
  integrations: [
    Sentry.browserTracingIntegration({
      tracingOrigins: ['localhost', 'ems-i18n.web.app', /^\//],
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: import.meta.env.DEV ? 1.0 : 0.01,
  tracePropagationTargets: ['localhost', 'ems-i18n.web.app', /^\//],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.use(createPinia());
app.use(PerfectScrollbar);
app.use(i18n);
app.use(Notifications);
app.use(vfm);
app.directive('tooltip', VTooltip);
app.directive('close-popper', VClosePopper);

app.component('VDropdown', Dropdown);
app.component('VTooltip', Tooltip);
app.component('VMenu', Menu);

loadLocaleMessages(i18n, 'fr').then(() => {
  app.mount('#app');
});

export default i18n;
