<template>
  <div class="row sticky-top bg-white py-3">
    <div class="col">
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button
              v-for="item in globalStore.getEventLocales"
              :key="item"
              class="nav-link"
              :class="translationsStore.currentEditLang === getSlug(item) ? 'active' : ''"
              @click="translationsStore.setLangAction(getSlug(item))"
              type="button">
            {{ item }}
          </button>
        </div>
      </nav>
    </div>
    <div class="col-auto">
      <button class="btn btn-warning text-white" type="button" @click="open()"
              v-if="translationsStore.autoTranslateAvailable && translationsStore.currentEditLang !== translationsStore.autoTranslateSourceLang">
        Traduction automatique
      </button>
      <button class="btn btn-info mx-2 text-white" type="button" data-bs-toggle="modal"
              data-bs-target="#exampleModal">
        {{ t('import') }}
      </button>
      <button class="btn btn-info me-2 text-white" type="button"
              @click="translationsStore.exportToCSVAction()">
        {{ t('export') }}
      </button>
      <button class="btn text-white"
              :class="globalStore.saveInProgress ? 'btn-secondary' : 'btn-success'" type="button"
              :disabled="globalStore.saveInProgress ? 'disabled' : undefined"
              @click="translationsStore.sendCurrentTranslationAction()">
        {{ t('save') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useModal } from 'vue-final-modal';
import { getSlug } from '@/utils';
import useGlobalStore from '../stores/globalStore';
import useTranslationStore from '../stores/translationsStore';
// import ModalConfirm from './ModalConfirm.vue';
import autoTranslateModal from './Modals/autoTranslateModal.vue';

const translationsStore = useTranslationStore();
const globalStore = useGlobalStore();
const { t } = useI18n();

const { open, close } = useModal({
  component: autoTranslateModal,
  attrs: {
    onConfirm() {
      close();
    },
  },
});

</script>

<style scoped>
</style>
