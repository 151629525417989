<template>
  <div>
    <div v-if="translationsStore.contentLoaded" class="mx-3">
      <TopBar />
      <WebappTranslationEditor />
    </div>
  </div>
</template>

<script setup>
import TopBar from '../components/TopBar.vue';
import WebappTranslationEditor from '../components/WebappTranslationEditor.vue';
import useGlobalStore from '../stores/globalStore';
import useTranslationStore from '../stores/translationsStore';

const globalStore = useGlobalStore();
const translationsStore = useTranslationStore();
translationsStore.getElementsAction(globalStore.eventID, globalStore.contextID).then(() => {
  translationsStore.defineWebappElementsAction();
  translationsStore.getAvailableAutoLanguagesAction();
});
</script>

<style scoped>

</style>
