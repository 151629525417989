<template>
  <div>
    <div v-if="globalStore.currentAttr.length">
      <KeyValueEditor v-for="(webappCustomTranslation, customTranslationKey) in translationsStore.webappCustomTranslations" :key="customTranslationKey" :data-key="customTranslationKey" :base-translation="false" :search-key="searchKey" :search-value="searchValue" />
    </div>
    <div v-else class="accordion" id="webappTranslationsAccordion">
      <div class="accordion-item">
        <h2 class="accordion-header" id="baseTextHeading">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#baseTextCollapse" aria-expanded="true" aria-controls="baseTextCollapse">
            {{ t('baseTextAccordion') }}
          </button>
        </h2>
        <div id="baseTextCollapse" class="accordion-collapse collapse show" aria-labelledby="baseTextHeading" data-bs-parent="#webappTranslationsAccordion">
          <div class="accordion-body px-2">
            <div class="row" v-if="translationsStore.isWebappLoaded && Object.keys(translationsStore.webappBaseTranslations).length > 1">
              <div class="col-6">
                <div class="row searchInput mb-2 mx-0">
                  <div class="col">
                    <input v-model="searchKey" class="w-100 border-0" :placeholder="t('searchKey')"/>
                  </div>
                  <div class="col-auto">
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row searchInput mb-2 mx-0">
                  <div class="col">
                    <input v-model="searchValue" class="w-100 border-0" :placeholder="t('searchValue')"/>
                  </div>
                  <div class="col-auto ps-0">
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <KeyValueEditor v-for="(webappBaseTranslation, baseTranslationKey) in translationsStore.webappBaseTranslations" :key="baseTranslationKey" :data-key="baseTranslationKey" :base-translation="true" :search-key="searchKey" :search-value="searchValue"/>
              </div>
            </div>
            <div v-else>
              <p>Traductions en cours de chargement</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="customTextHeading">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#customTextCollapse" aria-expanded="false" aria-controls="customTextCollapse">
            {{ t('customTextAccordion') }}
          </button>
        </h2>
        <div id="customTextCollapse" class="accordion-collapse collapse" aria-labelledby="customTextHeading" data-bs-parent="#webappTranslationsAccordion">
          <div class="accordion-body row">
            <div class="col-12">
              <KeyValueEditor v-for="(webappCustomTranslation, customTranslationKey) in translationsStore.webappCustomTranslations" :key="customTranslationKey" :data-key="customTranslationKey" :base-translation="false" :search-key="searchKey" :search-value="searchValue" />
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col ps-0">
                  <input class="form-control" v-model="newKey" :placeholder="t('newKeyPlaceholder')"/>
                </div>
                <div class="col px-0">
                  <input class="form-control" v-model="newValue" :placeholder="t('newValuePlaceholder')"/>
                </div>
                <div class="col-auto">
                  <button type="button" class="btn btn-success" @click="addNewItem"><i class="fa-solid fa-plus"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import useGlobalStore from '../stores/globalStore';
import useTranslationStore from '../stores/translationsStore';
import KeyValueEditor from './keyValueEditor.vue';

const globalStore = useGlobalStore();

const translationsStore = useTranslationStore();
const { t } = useI18n();
const searchKey = ref('');
const searchValue = ref('');
const newKey = ref('');
const newValue = ref('');

function addNewItem() {
  if (translationsStore.addWebappItemAction(newKey.value, newValue.value)) {
    newKey.value = '';
    newValue.value = '';
  }
}
</script>

<style scoped>
.searchInput {
  border: none;
  border-bottom: 2px solid rgba(10,39,59,0.22);
}
.searchInput:focus {
  border: none;
  border-bottom: 2px solid rgba(10,39,59,0.50);
}
</style>
