import { defineStore } from 'pinia';
import actions from './actions/translationActions';

const useTranslationStore = defineStore('translations', {
  state: () => ({
    translations: {},
    currentTranslation: {},
    currentContextID: '',
    currentElementID: '',
    currentEditLang: '',
    webappDictionary: {},
    webappBaseTranslations: {},
    webappCustomTranslations: {},
    availableAutoLanguages: [],
    defaultAutoLanguages: [],
    autoTranslateSourceLang: '',
    autoTranslateState: false,
    contentLoaded: false,
    webappLoaded: false,
    translationLoaded: false,
    autoTranslateAvailable: false,
  }),
  getters: {
    current: (state) => state.currentTranslation,
    isContentLoaded: (state) => state.contentLoaded,
    isWebappLoaded: (state) => state.webappLoaded,
    isTranslationLoaded: (state) => state.translationLoaded,
    getAttrFromCurrent: (state) => (attr) => state.currentTranslation[state.currentEditLang][attr],
    getBaseTranslations: (state) => (number, initial = 0) => {
      const keys = Object.keys(state.webappBaseTranslations).slice(initial, number);
      const result = {};
      for (let i = 0; i < number; i += 1) {
        if (keys[i]) result[keys[i]] = state.webappBaseTranslations[keys[i]];
      }
      return result;
    },
  },
  actions,
});

export default useTranslationStore;
