<template>
  <div>
    <div v-for="attr in globalStore.currentAttr" class="tab-content mt-3 px-3" :key="attr">
      <div class="mb-3">
        <KeyValueEditor v-if="translationsStore.currentTranslation[translationsStore.currentEditLang][attr] !== undefined" :dataKey="attr" :base-translation="false" :context="globalStore.contextID" :searchable="false" />
        <div v-else class="w-100 text-center">
          <h6>{{ t('dataLoadingError') }}</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import useTranslationStore from '../stores/translationsStore';
import KeyValueEditor from './keyValueEditor.vue';
import useGlobalStore from '../stores/globalStore';

const globalStore = useGlobalStore();
const translationsStore = useTranslationStore();
const { t } = useI18n();
</script>

<style scoped>

</style>
