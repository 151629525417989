<template>
  <div class="row mb-2" v-if="!props.searchable || (isInSearchKey && isInSearchValue)">
    <div class="col-12 text-start">
      <label class="form-label ms-2">{{ dataKey }}</label>
      <span class="badge bg-success small ms-2">
          <i v-if="globalStore.textAttr.length && !globalStore.textAttr.includes(dataKey)" class="fa-solid fa-code"></i>
      </span>
    </div>
    <div class="col-12">
      <ValueInput v-model="value" :data-key="dataKey" :html-type="globalStore.htmlAttr.includes(dataKey)"/>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import useGlobalStore from '../stores/globalStore';
import { getSlug } from '../utils';
import ValueInput from './ValueInput.vue';
import useTranslationStore from '../stores/translationsStore';

const globalStore = useGlobalStore();
const translationsStore = useTranslationStore();

const props = defineProps({
  dataKey: {
    type: String,
    required: true,
  },
  searchable: {
    type: Boolean,
    default: true,
  },
  context: {
    type: String,
    default: 'webapp',
  },
  baseTranslation: {
    type: Boolean,
    required: true,
  },
  searchKey: {
    type: String,
    default: '',
  },
  searchValue: {
    type: String,
    default: '',
  },
});

const value = computed({
  get() {
    if (props.context !== 'webapp') {
      return translationsStore.currentTranslation[translationsStore.currentEditLang][props.dataKey];
    }
    if (props.baseTranslation) {
      return translationsStore.webappBaseTranslations[props.dataKey][translationsStore.currentEditLang];
    }
    return translationsStore.webappCustomTranslations[props.dataKey][translationsStore.currentEditLang] || '';
  },
  set(newValue) {
    if (props.context !== 'webapp') {
      translationsStore.currentTranslation[translationsStore.currentEditLang][props.dataKey] = newValue;
    } else {
      translationsStore.setWebappElementAction(props.baseTranslation ? 'base' : 'custom', props.dataKey, newValue);
    }
  },
});

const isInSearchKey = computed(() => {
  if (!props.searchKey) return true;
  if (props.searchKey === '*') return true;
  return !!getSlug(props.dataKey).includes(getSlug(props.searchKey));
});
const isInSearchValue = computed(() => {
  if (!props.searchValue) return true;
  if (props.searchValue === '*') return true;
  if (getSlug(value.value).includes(getSlug(props.searchValue))) return true;
  if (translationsStore.webappDictionary[translationsStore.currentEditLang]
      && getSlug(translationsStore.webappDictionary[translationsStore.currentEditLang][props.dataKey]).includes(getSlug(props.searchValue))) return true;
  return getSlug(translationsStore.webappDictionary.fr[props.dataKey]).includes(getSlug(props.searchValue));
});
</script>

<style scoped>

</style>
