<template>
  <VueFinalModal
  class="htmlSourceModal"
  content-class="h-100 w-100 htmlSourceModalContent"
  overlay-transition="vfm-fade"
  content-transition="vfm-fade">
    <div class="d-flex px-3 pt-2 flex-column h-100">
      <div class="flex-grow-0 flex-shrink-1 pb-3" ref="modalHeader">
        <div class="row">
          <div class="col">
            <h3 class="mb-1">{{ t('htmlEditor') }}</h3>
          </div>
          <div class="col-auto my-auto">
            <i class="fa-solid fa-xmark fa-2xl" @click="emit('confirm')" style="cursor: pointer"></i>
          </div>
        </div>
      </div>
      <div class="flex-grow-1 pb-3" ref="cmDiv" style="overflow-y: hidden">
        <Codemirror
            v-model="value"
            placeholder=""
            :autofocus="true"
            :indent-with-tab="true"
            :tab-size="2"
            :line-numbers="true"
            :foldGutter="true"
            :extensions="extensions"
        />
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { Codemirror } from 'vue-codemirror';
import { useI18n } from 'vue-i18n';
import { computed, ref } from 'vue';
import { VueFinalModal } from 'vue-final-modal';
import { html } from '@codemirror/lang-html';
import { oneDark } from '@codemirror/theme-one-dark';

const { t } = useI18n();

const modalHeader = ref(null);
const cmDiv = ref(null);

const extensions = [html(), oneDark];

const emit = defineEmits(['confirm', 'update']);
const props = defineProps(['value']);

const value = computed({
  get() {
    return props.value || '';
  },
  set(newValue) {
    emit('update', newValue || '');
  },
});
</script>

<style>
.htmlSourceModal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1030 !important;
}

.htmlSourceModalContent {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-radius: 0.5rem;
}

.cm-editor {
  font-family: Hack, monospace !important;
  height: 100% !important;
}
</style>
