import { nextTick } from 'vue';
import { createI18n } from 'vue-i18n';

export const SUPPORT_LOCALES = ['en', 'fr'];

export function setI18nLanguage(i18n, locale) {
  const updatedi18n = i18n;
  if (i18n.mode === 'legacy') {
    updatedi18n.global.locale = locale;
  } else {
    updatedi18n.global.locale.value = locale;
  }
  document.querySelector('html').setAttribute('lang', locale);
}

export function setupI18n(options = { locale: 'fr' }) {
  const i18n = createI18n(options);
  setI18nLanguage(i18n, options.locale);
  return i18n;
}

export async function loadLocaleMessages(i18n, locale) {
  const messages = await import(`./locales/${locale}.json`);

  i18n.global.setLocaleMessage(locale, messages.default);

  return nextTick();
}
