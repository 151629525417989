import { defineStore } from 'pinia';
import actions from './actions/globalActions';

const useGlobalStore = defineStore('global', {
  state: () => ({
    eventID: '',
    contextID: '',
    elementID: '',
    eventLocales: [],
    currentAttr: [],
    htmlAttr: [],
    textAttr: [],
    userLang: '',
    loaded: false,
    saveInProgress: false,
    fbLoaded: false,
    onError: false,
    errorType: '',
  }),
  getters: {
    getLoaded: (state) => state.loaded,
    getEventLocales: (state) => state.eventLocales,
    getFirstLocale: (state) => {
      if (state.eventLocales.length > 0) {
        return state.eventLocales[0];
      }
      return 'francais';
    },
  },
  actions,
});

export default useGlobalStore;
