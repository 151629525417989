<template>
  <VueFinalModal
      class="autoTranslateModal"
      content-class="autoTranslateModalContent h-100 w-100 h-xl-50 w-xl-50 flex justify-content-between"
      overlay-transition="vfm-fade"
      content-transition="vfm-fade">
    <div class="d-flex justify-content-between">
      <h5>{{ t('autoTranslateModal.title') }} ({{ translationsStore.currentEditLang }}) </h5>
      <button type="button" class="btn-close" @click="emit('confirm')" :disabled="isProcessing"></button>
    </div>
    <div>
      <div v-if="isProcessing" class="text-center">
        <div class="d-none d-md-flex flower-spinner mx-auto">
          <div class="dots-container">
            <div class="bigger-dot">
              <div class="smaller-dot"></div>
            </div>
          </div>
        </div>
        <p>Translation is in progress, please wait</p>
        <div class="progress" role="progressbar" aria-label="Basic example" :aria-valuenow="processingPercent" aria-valuemin="0" aria-valuemax="100">
          <div class="progress-bar bg-success" :style="{width: `${processingPercent}%`}"></div>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-12 justify-content-center my-3">
          <form>
            <div class="form-group">
              <label>{{ t('autoTranslateModal.targetLangLabel') }}</label>
              <select v-model="targetLang" class="form-select"
                      v-if="translationsStore.autoTranslateAvailable && translationsStore.currentEditLang !== translationsStore.autoTranslateSourceLang">
                <option value="" selected disabled>{{ t('autoTranslateModal.targetLangPlaceholder') }}...</option>
                <optgroup :label="t('autoTranslateModal.frequentLanguagesOpt')">
                  <option v-for="language in translationsStore.defaultAutoLanguages"
                          :value="language.language" :key="language.language">{{ language.name }}
                  </option>
                </optgroup>
                <optgroup :label="t('autoTranslateModal.otherLanguagesOpt')">
                  <option v-for="language in translationsStore.availableAutoLanguages"
                          :value="language.language" :key="language.language">{{ language.name }}
                  </option>
                </optgroup>
              </select>
            </div>
            <div class="row mt-3">
              <div class="col-auto justify-content-center pe-0">
                <svg class="my-auto" style="height: 16px; width: 16px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
              </div>
              <div class="col">
                <b>{{ t('autoTranslateModal.dataEraseWarning') }}</b>
              </div>
              <div class="col-12 text-center mt-2">
                <button class="btn btn-info me-2 text-white" type="button"
                        @click="translationsStore.exportToCSVAction()">
                  {{ t('export') }}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 text-center mt-5">
          <button type="button" class="btn btn-primary text-white" @click="translateData" :disabled="targetLang === ''">
            {{ t('autoTranslateModal.startTranslateButton') }} ({{ translationsStore.currentEditLang }})
          </button>
        </div>
    </div>
    </div>
    <div>
      <button type="button" class="btn btn-secondary float-end"
              @click="emit('confirm')" :disabled="isProcessing">{{ t('cancel') }}
      </button>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { isImage, isURL } from '@/utils';
import { useNotification } from '@kyvg/vue3-notification';
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';
import { translateContent } from '@/services/gcloudTranslateService';
import useGlobalStore from '@/stores/globalStore';
import useTranslationStore from '../../stores/translationsStore';

const globalStore = useGlobalStore();
const translationsStore = useTranslationStore();
const { t } = useI18n();
const targetLang = ref('');
const isProcessing = ref(false);
const totalTranslations = ref(0);
const processedTranslations = ref(0);

const emit = defineEmits(['confirm']);

const notification = useNotification();

const processingPercent = computed(() => (100 * processedTranslations.value) / totalTranslations.value);
async function translateData() {
  isProcessing.value = true;

  const translateDataQueue = [];

  if (globalStore.contextID !== 'webapp') {
    Object.keys(translationsStore.currentTranslation[translationsStore.currentEditLang]).forEach((key) => {
      const translateDataPromise = new Promise((resolve) => {
        const data = translationsStore.currentTranslation[translationsStore.autoTranslateSourceLang][key];
        if (data === '' || (isURL(data) || isImage(data))) {
          translationsStore.currentTranslation[translationsStore.currentEditLang][key] = '';
          processedTranslations.value += 1;
          resolve();
          return;
        }
        translateContent(targetLang.value, data).then((result) => {
          translationsStore.currentTranslation[translationsStore.currentEditLang][key] = result.translatedText;
        });
        resolve();
      });
      translateDataQueue.push(translateDataPromise);
    });
  } else {
    const baseData = Object.keys(translationsStore.webappBaseTranslations);
    const customData = Object.keys(translationsStore.webappCustomTranslations);

    totalTranslations.value = baseData.length + customData.length;
    baseData.forEach((key) => {
      const translateDataPromise = new Promise((resolve) => {
        let data = translationsStore.webappBaseTranslations[key][translationsStore.autoTranslateSourceLang];
        if (data === '') {
          data = translationsStore.webappDictionary[translationsStore.autoTranslateSourceLang][key];
        }
        translateContent(targetLang.value, data).then((result) => {
          translationsStore.webappBaseTranslations[key][translationsStore.currentEditLang] = result.translatedText;
          processedTranslations.value += 1;
          resolve();
        });
      });
      translateDataQueue.push(translateDataPromise);
    });

    customData.forEach((key) => {
      const translateDataPromise = new Promise((resolve) => {
        const data = translationsStore.webappCustomTranslations[key]?.[translationsStore.autoTranslateSourceLang];
        if (data === '' && (isURL(data) && isImage(data))) {
          processedTranslations.value += 1;
          resolve();
          return;
        }
        translateContent(targetLang.value, data).then((result) => {
          translationsStore.webappCustomTranslations[key][translationsStore.currentEditLang] = result.translatedText;
          processedTranslations.value += 1;
          resolve();
        });
      });
      translateDataQueue.push(translateDataPromise);
    });
  }

  Promise.all(translateDataQueue).then(() => {
    notification.notify({
      type: 'success',
      title: 'La traduction est terminée',
    });
    emit('confirm');
  });
}
</script>

<style>
.autoTranslateModal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1030 !important;
}

.autoTranslateModalContent {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-radius: 0.5rem;
}

.dark .autoTranslateModalContent {
  background: #000;
}
</style>
