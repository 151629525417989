<template>
  <div class="min-vh-100">
    <LoadingView v-if="!globalStore.getLoaded && !globalStore.onError"/>
    <ErrorView v-else-if="globalStore.onError"/>
    <ItemView v-else-if="globalStore.elementID" />
    <WebappView v-else-if="globalStore.contextID && globalStore.contextID === 'webapp'" />
    <ContextView v-else-if="globalStore.contextID" />
    <EventView v-else />
    <importFileModal />
    <notifications position="bottom left"/>
    <ModalsContainer />
  </div>
</template>

<script setup>
import ImportFileModal from '@/components/Modals/importFileModal.vue';
import { ModalsContainer } from 'vue-final-modal';
import useGlobalStore from './stores/globalStore';
import ContextView from './views/ContextView.vue';
import WebappView from './views/WebappView.vue';
import ErrorView from './views/ErrorView.vue';
import EventView from './views/EventView.vue';
import LoadingView from './views/LoadingView.vue';
import ItemView from './views/ItemView.vue';

const globalStore = useGlobalStore();
globalStore.setConfigAction();
</script>

<style>
</style>
