import {
  get,
  child,
  ref,
  set,
  remove,
} from 'firebase/database';
import getInstance from './firebaseService';

/**
 * This function get attributes from database for your element in your context
 * @param eventID - The ID of the event
 * @param attributes - The attributes you want to get in database
 * @param contextID - The ID of the context
 * @param elementID - The ID of the element
 * @return {Promise<{}>}
 */
export async function getElements(eventID, attributes, contextID, elementID) {
  const database = await getInstance();
  const dbRef = ref(database);
  const result = {};
  const localesSnapshot = await get(child(dbRef, `translations/${eventID}/`));
  if (!localesSnapshot.exists()) {
    return {};
  }

  const translations = localesSnapshot.val();
  Object.entries(translations).forEach((locale) => {
    Object.entries(locale[1]).forEach((context) => {
      if (!contextID || context[0] === contextID) {
        Object.entries(context[1]).forEach((element) => {
          if (!result[context[0]]) {
            result[context[0]] = {};
          }
          if (!elementID || element[0] === elementID) {
            if (attributes) {
              attributes.forEach((attr) => {
                if (!result[context[0]][element[0]]) {
                  result[context[0]][element[0]] = {};
                }
                if (!result[context[0]][element[0]][locale[0]]) {
                  result[context[0]][element[0]][locale[0]] = {};
                }
                result[context[0]][element[0]][locale[0]][attr] = '';
                if (element[1][attr]) {
                  result[context[0]][element[0]][locale[0]][attr] = element[1][attr];
                }
              });
            } else {
              result[context[0]][element[0]][locale[0]] = { ...element[1] };
            }
          }
        });
      }
    });
  });
  return result;
}

/**
 * Set translation element in database
 * @author Elouan MAILLY <elouan@captag.fr>
 * @param {String} eventID - The ID of the event
 * @param {String} contextID - the ID of the context (ex: agendas, speakers, webapp)
 * @param {String} elementID - the ID of the element
 * @param {Object} value - The value you want to set in database
 * @returns {Promise<void>}
 */
export async function setElement(eventID, contextID, elementID, value) {
  const database = await getInstance();
  const dbRef = ref(database);
  const allGetPromises = [];
  const allSetPromises = [];

  Object.keys(value).forEach((locale) => {
    const getPromise = new Promise((resolveGetPromise) => {
      get(child(dbRef, `translations/${eventID}/${locale}/${contextID}/${elementID}`)).then((elementSnapshot) => { // we get data from Firebase in node translations/eventID/locale/contextID/elementID
        let storedElement = elementSnapshot.exists() ? elementSnapshot.val() : {}; // If no data returned from firebase, we create an empty object
        /*
         * This part of code is to merge datas from what user types to what is in database.
         */
        Object.keys(value[locale]).forEach((attr) => {
          if (value[locale][attr] && value[locale][attr] !== '') { // If attribute exists in the value and is not empty, we use it in storedElement
            storedElement = { ...storedElement, [attr]: value[locale][attr] };
          }
          if (value[locale][attr] === '' && storedElement[attr]) { // If attributes is empty and exists in database, we delete it because it should not being in database
            delete storedElement[attr];
          }
        });
        /*
         * We create another promise. This one will be used to wait Firebase set()
         */
        const setPromise = new Promise((resolveSetPromise) => {
          set(child(dbRef, `translations/${eventID}/${locale}/${contextID}/${elementID}`), storedElement).then(() => resolveSetPromise());
        });
        allSetPromises.push(setPromise);
        resolveGetPromise();
      });
    });
    allGetPromises.push(getPromise);
  });
  await Promise.all(allGetPromises);
  await Promise.all(allSetPromises);
}

/**
 * Get selected webapp attributes from database
 * @param eventID - The ID of the event
 * @param attributes - The attributes we want to get from database
 * @return {Promise<{}>}
 */
export async function getWebappElements(eventID, attributes) {
  const database = await getInstance();
  const dbRef = ref(database);
  const result = {};
  const localesSnapshot = await get(child(dbRef, `translations/${eventID}/`));
  if (!localesSnapshot.exists()) {
    return {};
  }

  const translations = localesSnapshot.val();
  Object.entries(translations).forEach((locale) => {
    Object.entries(locale[1]).forEach((context) => {
      if (context[0] === 'webapp') {
        if (!result[context[0]]) {
          result[context[0]] = {};
        }
        if (attributes && attributes.length) {
          attributes.forEach((attr) => {
            if (!result[context[0]][attr]) {
              result[context[0]][attr] = {};
            }
            if (!result[context[0]][attr][locale[0]]) {
              result[context[0]][attr][locale[0]] = '';
            }
            if (attr) {
              result[context[0]][attr][locale[0]] = context[1][attr];
            }
          });
        } else {
          Object.keys(context[1]).forEach((attr) => {
            if (!result[context[0]][attr]) {
              result[context[0]][attr] = {};
            }
            if (!result[context[0]][attr][locale[0]]) {
              result[context[0]][attr][locale[0]] = '';
            }
            result[context[0]][attr][locale[0]] = context[1][attr];
          });
        }
      }
    });
  });
  return result;
}

/**
 * Set translation context in database
 * @author Elouan MAILLY <elouan@captag.fr>
 * @param {String} eventID - The ID of the event
 * @param {String} contextID - The ID of the context (ex: agendas, speakers, webapp)
 * @param {String[]} locales - The list of all event Locales
 * @param {Object} value - The value you want to set in database
 * @returns {Promise<void>}
 */
export async function setContext(eventID, contextID, locales, value) {
  const database = await getInstance();
  const dbRef = ref(database);
  const allGetPromises = [];
  const allSetPromises = [];

  locales.forEach((locale) => {
    const getPromise = new Promise((resolveGetPromise) => {
      get(child(dbRef, `translations/${eventID}/${locale}/${contextID}`)).then((elementSnapshot) => { // we get data from Firebase in node translations/eventID/locale/contextID/elementID
        let storedElement = elementSnapshot.exists() ? elementSnapshot.val() : {}; // If no data returned from firebase, we create an empty object
        /*
         * This part of code is to merge datas from what user types to what is in database.
         */
        Object.keys(value).forEach((attr) => {
          if (value[attr][locale] && value[attr][locale] !== '') { // If attribute exists in the value and is not empty, we use it in storedElement
            storedElement = { ...storedElement, [attr]: value[attr][locale] };
          }
          if ((!value[attr][locale] || value[attr][locale] === '') && storedElement[attr]) { // If attributes does not exist OR is empty and exists in database, we delete it because it should not being in database
            storedElement[attr] = null;
          }
          if (Object.keys(storedElement).length) {
            /*
             * We create another promise. This one will be used to wait Firebase set()
             */
            const setPromise = new Promise((resolveSetPromise) => {
              set(child(dbRef, `translations/${eventID}/${locale}/${contextID}`), storedElement).then(() => {
                resolveSetPromise();
              });
            });
            allSetPromises.push(setPromise);
          }
        });
        resolveGetPromise();
      });
    });
    allGetPromises.push(getPromise);
  });
  await Promise.all(allGetPromises);
  await Promise.all(allSetPromises);
}

/**
 * This function calls webapp project to gel all the dictionary from the current webapp version
 * @return {Promise<any>}
 */
export async function getWebappDictionary() {
  const response = await fetch('https://captag.events/adm/getTranslations/');
  return response.json();
}

/**
 * Delete item in database
 * @param eventID - The ID of the event
 * @param contextID - The iD of the context
 * @param locales - The locales of the event
 * @param elementID - The ID of the element we want to delete
 * @return {Promise<void>}
 */
export async function deleteItem(eventID, contextID, locales, elementID) {
  const database = await getInstance();
  const dbRef = ref(database);
  const allPromises = [];

  locales.forEach((locale) => {
    const promise = new Promise(() => {
      remove(child(dbRef, `translations/${eventID}/${locale}/${contextID}/${elementID}`)).then();
    });
    allPromises.push(promise);
  });
  await allPromises.all();
}
